<template>
  <v-container>
    <PageTitle text="My Experience" />
    <PageText :text="p1" />
    <PageImage path="img/sarah-standing.jpg" :md="4" />
    <PageText :text="p2" />
    <PageText :text="p3" />
    <PageQuote :text="q1" person="Emily Dickinson" />
    <PageImage path="img/ss-forest-sunbeams.jpg" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageQuote from '@/components/PageQuote.vue';
import PageImage from '@/components/PageImage.vue';

export default {
  components: {
    PageText, PageTitle, PageQuote, PageImage,
  },
  data: () => ({
    p1: `
      I am a Licensed Clinical Social Worker (LCSW-38369) and registered supervisor in the state of
      Idaho. I have a Bachelor’s of Science in Psychology from the University of Idaho and a
      graduate degree in Social Work from Boise State University.
    `,
    p2: `
      My first post-graduate-school experience was working as a counselor at a residential facility
      for homeless adults with mental illness in Florida. I thoroughly enjoyed working where my
      clients lived and I hold this experience close to my heart. Following my return to Idaho, I
      worked as a mental health therapist and eventual supervisor in the behavioral health
      department of a community healthcare center. Having the ability to work on a multidisciplinary
      team allowed me to gain perspective and valuable experience in the field.
    `,
    p3: `
     I subsequently felt inspired to start my own practice and thus, Coeur Counseling was born. I am
     grateful to have the opportunity to mesh my career and business with my passion. My sincerest
     aspiration in my practice is to assist my clients in gaining a felt sense of hope in their
     lives.
    `,
    q1: `
      “Hope is the thing with feathers
      That perches in the soul
      And sings the tune without words
      And never stops at all.”
    `,
  }),
};
</script>

<style scoped>
</style>
